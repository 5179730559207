import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useGlobalVariables from "hooks/useGlobalVariables";

import Collapse from "components/ui/collapse";
import PanelWrapper from "components/ui/collapse/panelWrapper";
import KenoBalls from "components/keno/bets/kenoBalls";
import BetTicketBorder from "components/ui/betTicketBorder";

import useAppSelector from "hooks/store/useAppSelector";

import { selectOddFormat, selectSession } from "store/slices/auth/selectors";

import { isNullish, makeCurrencyText, mergeClassNames, numberWithSpaces } from "utils/common";
import { formatDateTime } from "utils/dateTime";

import { getBetStateText, getBetEventResult, calculateTotalOdds } from "utils/bets";

import { BETSLIP_MODES } from "constants/betslip.constants";
import { GAME_TYPE, KENO_BALL_SIZE } from "constants/game.constants";
import runMarketUtilsFunction from "utils/markets/run";
import { ODD_FORMATS } from "constants/market.constants";
import BetBalls from "components/luckySix/betBalls/index.jsx";

const PendingBetContent = ({ borderSvgStyle, data }) => {
	const { isMobile } = useGlobalVariables();
	const { t } = useTranslation();
	const session = useAppSelector(selectSession);
	const oddFormat = useAppSelector(selectOddFormat);

	const isMultiBet = data.type === BETSLIP_MODES.MULTI;

	const betElement = runMarketUtilsFunction("makeBetText", [{ ...data, group: data.market }], data.gameType);
	const oddText =
		numberWithSpaces(runMarketUtilsFunction("convertToFormat", [data.factor, oddFormat])) +
		(oddFormat === ODD_FORMATS.DECIMAL ? "x" : "");
	const possibleWinAmount = makeCurrencyText(data.possibleWin, {
		decimalCount: data.decimalCount,
		code: data.currencyId
	});

	const wrapDangerouslySetInnerHTML = (maxWidth, innerHTML) =>
		`<div style="max-width: ${maxWidth}px; overflow: hidden; text-overflow: ellipsis;">${innerHTML}</div>`;

	const getBetPromotionAmount = () => {
		return data.possiblePromotionAmount;
	};

	const renderTotal = () => {
		const total = calculateTotalOdds(data.bets, session);
		if (window.isNaN(total)) {
			return 0;
		}

		return `x${numberWithSpaces(total)}`;
	};

	return (
		<div className="vs--pending-item-content vs--flex vs--flex-col vs--title-gray-80 vs--font-small">
			{isMultiBet ? (
				<div className="vs--pending-item-content-items vs--flex vs--flex-col vs--justify-between">
					<div>
						<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
							<div className="vs--pl-8 vs--pr-8">
								{t("bet.betslipId")}:&nbsp;{data.betSlipId}
							</div>
							<div className="vs--pl-8 vs--pr-8">{formatDateTime(data.betTime)}</div>
						</div>
						<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
							<div className="vs--pl-8 vs--pr-8">{t("bet.totalOdds")}</div>
							<div className="vs--pl-8 vs--pr-8">{renderTotal()}</div>
						</div>
						<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
							<div className="vs--pl-8 vs--pr-8">{t("bet.stake")}</div>
							<div className="vs--pl-8 vs--pr-8">
								{makeCurrencyText(data.amount, { decimalCount: data.decimalCount, code: data.currencyId })}
							</div>
						</div>
						{!isNullish(data.promotionPercent) && (
							<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
								<div className="vs--pl-8 vs--pr-8 vs--font-uppercase">{t("common.comboboost")}</div>
								<div className="vs--pl-8 vs--pr-8">
									{`(${makeCurrencyText(getBetPromotionAmount(), { decimalCount: data.decimalCount, code: data.currencyId })}) ${data.promotionPercent}%`}
								</div>
							</div>
						)}
					</div>
					<div className="vs--pending-item-content-row vs--pending-item-content-row-last vs--flex vs--justify-between vs--align-center vs--title-brand vs--font-normal vs--font-medium vs--pt-8 vs--pb-8">
						<div className="vs--pl-8 vs--pr-8 vs--text-cut" title={t("bet.possibleWin")}>
							{t("bet.possibleWin")}:&nbsp;
						</div>
						<div className="vs--pl-8 vs--pr-8 vs--text-cut" title={possibleWinAmount}>
							{possibleWinAmount}
						</div>
					</div>
				</div>
			) : (
				<div className="vs--pending-item-content-items vs--flex vs--flex-col vs--justify-between">
					<div>
						<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
							<div className="vs--pl-8 vs--pr-8">
								{t("common.eventId")}:&nbsp;{data.eventId}
							</div>
							<div className="vs--pl-8 vs--pr-8">{formatDateTime(data.eventStartTime)}</div>
						</div>
						<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
							<div className="vs--pl-8 vs--pr-8">
								{t("bet.betId")}:&nbsp;{data.betId}
							</div>
							<div className="vs--pl-8 vs--pr-8">{formatDateTime(data.betTime)}</div>
						</div>
						{data.gameType === GAME_TYPE.KENO && (
							<div className="vs--pending-item-content-row vs--pending-item-content-row-result vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
								<div className="vs--pending-item-keno-result vs--flex vs--flex-col">
									<div className="vs--flex vs--justify-between vs--align-center vs--pl-8 vs--pr-8">
										<KenoBalls
											balls={data.outcome.split(":").map((b) => Number(b))}
											size={KENO_BALL_SIZE.SMALL}
										/>
										<span>{oddText}</span>
									</div>
								</div>
							</div>
						)}
						{data.gameType === GAME_TYPE.LUCKY_SIX &&
							(data.outcome.includes(":") ? (
								<div className="vs--pending-item-content-row vs--pending-item-content-row-result vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
									<div className="vs--pending-item-keno-result vs--flex vs--flex-col">
										<div className="vs--flex vs--justify-between vs--align-center vs--pl-8 vs--pr-8">
											<div className="vs--lucky-six-balls">
												<BetBalls
													numbers={data.outcome.split(":").map((num) => +num)}
													border="big"
													clickable={false}
												/>
											</div>
											<span>{oddText}</span>
										</div>
									</div>
								</div>
							) : (
								<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
									<div
										className="vs--history-table-details-item-value vs--font-regular vs--font-small vs--pl-8 vs--pr-8"
										title={betElement}
										dangerouslySetInnerHTML={{ __html: wrapDangerouslySetInnerHTML(250, betElement) }}
									/>
									<div dir="ltr" className="vs--pl-8 vs--pr-8">
										{oddText}
									</div>
								</div>
							))}
						{![GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX].includes(data.gameType) && (
							<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
								<div
									className="vs--history-table-details-item-value vs--font-regular vs--font-small vs--pl-8 vs--pr-8"
									title={betElement}
									dangerouslySetInnerHTML={{ __html: wrapDangerouslySetInnerHTML(250, betElement) }}
								/>
								<div dir="ltr" className="vs--pl-8 vs--pr-8">
									{oddText}
								</div>
							</div>
						)}
						<div className="vs--pending-item-content-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8">
							<div className="vs--pl-8 vs--pr-8">{t("bet.stake")}</div>
							<div className="vs--pl-8 vs--pr-8">
								{makeCurrencyText(data.amount, { decimalCount: data.decimalCount, code: data.currencyId })}
							</div>
						</div>
					</div>
					<div className="vs--pending-item-content-row vs--pending-item-content-row-last vs--flex vs--justify-between vs--align-center vs--title-brand vs--font-normal vs--font-medium vs--pt-8 vs--pb-8">
						<div className="vs--pl-8 vs--pr-8">{t("bet.possibleWin")}:&nbsp;</div>
						<div className="vs--pl-8 vs--pr-8" title={possibleWinAmount}>
							{possibleWinAmount}
						</div>
					</div>
				</div>
			)}
			<BetTicketBorder svgProps={borderSvgStyle} />
		</div>
	);
};

PendingBetContent.propTypes = {
	data: PropTypes.object,
	currencyCode: PropTypes.string,
	beautifyNumber: PropTypes.func,
	fixDecimal: PropTypes.func,
	t: PropTypes.func
};

export default PendingBetContent;

import { getEventByIdThunk } from "store/slices/lucky-six/thunks.js";
import {
	addDrawnBallAction,
	addSelectedBallsAction,
	clearDrawnBallsAction,
	removeSelectedBallsAction,
	setDrawnBallsAction,
	setSelectedBallsAction
} from "store/slices/lucky-six/actions.js";

export const addEventByIdCases = (builder) => {
	builder.addCase(getEventByIdThunk.pending, (state) => {
		state.marketsLoading = true;
	});

	builder.addCase(getEventByIdThunk.fulfilled, (state, { payload: event }) => {
		state.marketsLoading = false;
		state.resultMarkets = {
			...state.resultMarkets,
			[event.id]: event.markets
		};
	});

	builder.addCase(getEventByIdThunk.rejected, (state) => {
		state.marketsLoading = true;
	});
};

export const addBallCases = (builder) => {
	builder.addCase(setSelectedBallsAction, (state, { payload }) => {
		state.selectedBalls = payload.balls;
	});
	// Handling both single number case and number[]
	builder.addCase(addSelectedBallsAction, (state, { payload }) => {
		state.selectedBalls = state.selectedBalls.concat(Array.isArray(payload.balls) ? payload.balls : [payload.balls]);
	});
	// Handling both single number case and number[]
	builder.addCase(removeSelectedBallsAction, (state, { payload }) => {
		state.selectedBalls = state.selectedBalls.filter((ball) =>
			Array.isArray(payload.balls) ? !payload.balls.includes(ball) : payload.balls !== ball
		);
	});
};

export const addDrawnBallCases = (builder) => {
	builder.addCase(addDrawnBallAction, (state, { payload }) => {
		state.drawnBalls = [...state.drawnBalls, payload.ball];
	});

	builder.addCase(setDrawnBallsAction, (state, { payload }) => {
		state.drawnBalls = [...payload.balls];
	});

	builder.addCase(clearDrawnBallsAction, (state) => {
		state.drawnBalls = [];
	});
};

import { createSlice } from "@reduxjs/toolkit";
import { sliceName, initialState } from "./initialState";
import { addBallCases, addDrawnBallCases, addEventByIdCases } from "store/slices/lucky-six/extraReducers.js";

const luckySixSlice = createSlice({
	name: sliceName,
	initialState,
	extraReducers: (builder) => {
		addBallCases(builder);
		addEventByIdCases(builder);
		addDrawnBallCases(builder);
	}
});

export const luckySixSliceName = sliceName;
export const luckySixReducer = luckySixSlice.reducer;

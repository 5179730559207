const sliceName = "lucky-six";

const initialState = {
	rtPsLoading: false,
	marketsLoading: false,
	resultRtPs: {},
	resultMarkets: {},
	selectedBalls: [],
	drawnBalls: []
};

export { sliceName, initialState };

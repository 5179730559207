import { createAction } from "@reduxjs/toolkit";
import { sliceName } from "store/slices/lucky-six/initialState.js";

export const setSelectedBallsAction = createAction(`${sliceName}/setSelectedBalls`, (balls) => {
	return {
		payload: { balls }
	};
});

export const addSelectedBallsAction = createAction(`${sliceName}/addSelectedBalls`, (balls) => {
	return {
		payload: { balls }
	};
});

export const removeSelectedBallsAction = createAction(`${sliceName}/removeSelectedBalls`, (balls) => {
	return {
		payload: { balls }
	};
});

export const addDrawnBallAction = createAction(`${sliceName}/addDrawnBall`, (ball) => {
	return {
		payload: { ball }
	};
});

export const setDrawnBallsAction = createAction(`${sliceName}/addDrawnBalls`, (balls) => {
	return {
		payload: { balls }
	};
});

export const clearDrawnBallsAction = createAction(`${sliceName}/clearDrawnBalls`, () => {
	return {
		payload: {}
	};
});

import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import KenoBalls from "components/keno/bets/kenoBalls";
import Tooltip from "components/ui/tooltip";
import BetTicketBorder from "components/ui/betTicketBorder";

import { removeBetAction, updateBetStakeAction } from "store/slices/betslip/actions";

import {
	validateForAmount,
	numberWithSpaces,
	makeCurrencyText,
	isMobile,
	toFixedFloor,
	mergeClassNames
} from "utils/common";
import { getBetEventName } from "utils/bets";
import runMarketUtilsFunction from "utils/markets/run";

import {
	BETSLIP_MODES,
	BETSLIP_STAKE_MODES,
	BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS
} from "constants/betslip.constants.js";
import { GAME_TYPE_ICON_MAPPER, GAME_TYPE, KENO_BALL_SIZE } from "constants/game.constants";
import { BONUS_TYPE, BONUS_WIN_TYPE } from "constants/bonus.constants";

import betType from "types/bet.type";
import useGlobalVariables from "hooks/useGlobalVariables";
import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { selectUseBonus } from "store/slices/bonus/selectors";
import { selectSessionCurrency, selectSessionCurrentBonus, selectOddFormat } from "store/slices/auth/selectors";
import {
	selectBetslipMode,
	selectBets,
	selectBetslipStake,
	selectBetslipStakeMode
} from "store/slices/betslip/selectors";
import BetBalls from "components/luckySix/betBalls/index.jsx";

/** SIngle Bet Component in Betslip */
const SingleBet = ({ borderSvgStyle, bet, index, groupedRepeatedBets, focusedStakeId, setFocusedStakeId }) => {
	const { t } = useTranslation();

	const useBonus = useAppSelector(selectUseBonus);
	const currentBonus = useAppSelector(selectSessionCurrentBonus);
	const currency = useAppSelector(selectSessionCurrency);
	const oddFormat = useAppSelector(selectOddFormat);
	const mode = useAppSelector(selectBetslipMode);
	const bets = useAppSelector(selectBets);
	const stake = useAppSelector(selectBetslipStake);
	const stakeMode = useAppSelector(selectBetslipStakeMode);

	const dispatch = useAppDispatch();

	const didMountRef = useRef(false);
	const notCombinableElRef = useRef(null);
	const { isMobile } = useGlobalVariables();
	const isFreeAmountBonus = useBonus && currentBonus?.bonusType === BONUS_TYPE.FREEAMOUNT;

	// true if user can't change stake
	const readOnly = useBonus && !isFreeAmountBonus;

	/** Function which fires on stake input change
	 * @function
	 * @param {object} e - event object
	 * @memberOf SingleBet
	 */
	const onHandleInputChange = (e) => {
		let value = e.target.value.replace(/\s/g, "");

		if (validateForAmount(value, currency.decimalCount ?? 0)) {
			dispatch(updateBetStakeAction(value, bet.key));
		}
	};

	const onInputFocus = () => setFocusedStakeId(bet.key);

	const onRemoveBet = (key, betId, eventId) => {
		dispatch(removeBetAction({ key, betId, eventId }));
		setFocusedStakeId(null);
	};

	/** Function which checks if the stake is valid
	 * @function
	 * @param {string} value - stake value
	 * @returns {boolean}
	 * @memberOf SingleBet
	 */
	const hasError = (value) =>
		(Number(value) < (currency.singleMin ?? 0) || Number(value) > (currency.singleMax ?? Infinity)) && value !== "";

	/** Update stake input value on "stake per bet switcher" or common stake input value change */
	useEffect(() => {
		if (didMountRef.current) {
			if (stakeMode === BETSLIP_STAKE_MODES.PER_BET) {
				if (!bet.isExpired) {
					dispatch(updateBetStakeAction(stake, bet.key));
				}
			} else {
				if (!bet.isExpired) {
					const notExpiredBets = bets.filter((b) => !b.isExpired);
					const notExpiredBetsIndexes = bets
						.map((b, index) => ({ isExpired: b.isExpired, index: index }))
						.filter((b) => !b.isExpired)
						.map((b) => b.index);

					dispatch(
						updateBetStakeAction(
							stake === ""
								? stake
								: index !== notExpiredBetsIndexes[notExpiredBetsIndexes.length - 1]
									? toFixedFloor(Number(stake) / notExpiredBets.length, currency.decimalCount ?? 2)
									: toFixedFloor(
											Number(stake) -
												Number(
													toFixedFloor(Number(stake) / notExpiredBets.length, currency.decimalCount ?? 2)
												) *
													notExpiredBets.length +
												Number(
													toFixedFloor(Number(stake) / notExpiredBets.length, currency.decimalCount ?? 2)
												),
											currency.decimalCount ?? 2
										),
							bet.key
						)
					);
				}
			}
		} else didMountRef.current = true;
	}, [stake, stakeMode, useBonus]);

	useEffect(() => {
		if (bet.isExpired) {
			setTimeout(() => onRemoveBet(bet.key), 3000);
		}
	}, [bet.isExpired]);

	/** Cleaning focused Bet stake on Unmount */
	useEffect(() => {
		return () => {
			setFocusedStakeId(null);
		};
	}, []);

	const renderPossibleWinAmount = () => {
		const possibleWinAmount =
			bet.stake * bet.factor - (useBonus && currentBonus.winType === BONUS_WIN_TYPE.PURE ? bet.stake : 0);
		return makeCurrencyText(window.isNaN(possibleWinAmount) ? 0 : possibleWinAmount, {
			currencyId: currency.currencyId ?? 0,
			decimalCount: currency.decimalCount ?? 0
		});
	};

	return (
		<div
			className={mergeClassNames(
				"vs--betslip-bet",
				mode === BETSLIP_MODES.SINGLE && "vs--mb-8",
				mode === BETSLIP_MODES.SINGLE && bet.isExpired && "vs--single-bet-expired-single",
				bet.isExpired && mode !== BETSLIP_MODES.SINGLE && "vs--single-bet-expired"
			)}
		>
			<div
				className={mergeClassNames(
					"vs--betslip-bet-header",
					groupedRepeatedBets?.[bet.eventId] && "vs--betslip-bet-header-combined-event-error",
					"vs--flex vs--align-center"
				)}
			>
				<div className="vs--flex vs--flex-equal vs--align-center vs--justify-between vs--pl-8 vs--pr-8">
					<div className="vs--flex vs--align-center">
						{bet.isExpired && <span className="vs--betslip-bet-header-dot vs--mr-8"></span>}
						<i className={"vs--font-20 " + GAME_TYPE_ICON_MAPPER[bet.gameType] || null} />
						<span
							title={getBetEventName(bet)}
							className="vs--text-cut vs--title-white vs--font-regular vs--font-small vs--pl-8"
						>
							{getBetEventName(bet)}
						</span>
					</div>
					{bet.isExpired ? (
						<span className="vs--title-red vs--font-regular vs--font-small vs--single-bet-heading-content-error">
							{t("bet.expired")}
						</span>
					) : (
						<span className="vs--flex vs--title-white vs--font-regular vs--font-small ">
							<span className="vs--mr-6">ID</span>
							<span>{bet.id}</span>
						</span>
					)}
				</div>
				{!bet.isExpired && (
					<div className="vs--betslip-bet-actions-btn vs--pl-4 vs--pr-4" onClick={() => onRemoveBet(bet.key)}>
						<i className="ic_close vs--font-20"></i>
					</div>
				)}
			</div>
			<div className="vs--betslip-bet-content vs--pb-8 vs--pt-8 vs--pl-8 vs--pr-8">
				{Boolean(bet.group) && (
					<div
						title={bet.group}
						className="vs--single-bet-content-name vs--text-cut vs--font-regular vs--font-normal"
					>
						{`${bet.group}`}
					</div>
				)}
				<div className="vs--flex vs--justify-between vs--align-center vs--mt-4 vs--mb-4">
					{bet?.gameType === GAME_TYPE.KENO ? (
						<KenoBalls balls={bet.name.split(",").map((b) => Number(b))} size={KENO_BALL_SIZE.SMALL} />
					) : bet?.gameType === GAME_TYPE.LUCKY_SIX && bet.name?.includes(",") ? (
						<div className="vs--lucky-six-balls">
							<BetBalls numbers={bet.name.split(",").map((number) => +number)} clickable={false} border="big" />
						</div>
					) : (
						<b
							className="vs--title-brand vs--font-regular vs--font-normal"
							dangerouslySetInnerHTML={{ __html: bet.name }}
						></b>
					)}
					<span className="vs--title-brand vs--font-medium vs--font-normal vs--text-right vs--single-bet-content-factor">
						{numberWithSpaces(runMarketUtilsFunction("convertToFormat", [bet.factor, oddFormat]))}
					</span>
				</div>

				{mode === BETSLIP_MODES.SINGLE ? (
					<div
						className={mergeClassNames(
							"vs--single-bet-stake vs--flex vs--justify-between",
							hasError(bet.stake) && "vs--betslip-error"
						)}
					>
						<div
							className={mergeClassNames(
								"vs--betslip-bet-input",
								focusedStakeId === bet.key && "vs--betslip-bet-input-focused",
								readOnly && "vs--stake-input-disabled"
							)}
						>
							<input
								{...{ [BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS.BET_STAKE]: true }}
								className="vs--font-normal vs--font-regular vs--pr-8 vs--pl-8"
								value={numberWithSpaces(bet.stake)}
								onChange={onHandleInputChange}
								onFocus={onInputFocus}
								placeholder={t("bet.stake")}
								readOnly={readOnly}
								inputMode={isMobile ? "tel" : "text"}
							/>
							{useBonus ? (
								<i className="ic_bonus vs--stake-input-bonus-icon vs--stake-input-bonus-icon-right" />
							) : null}
						</div>
						{Number(bet.stake) > (currency.singleMax ?? Infinity) && bet.stake !== "" ? (
							<div className="vs--single-bet-stake-text vs--flex vs--flex-col vs--justify-center vs--text-right vs--single-bet-stake-error">
								<Tooltip placement="top" title={t("bet.maxBetAmountIs")}>
									<span className="vs--title-red vs--font-regular vs--font-small vs--pb-4">
										{t("bet.maxBetAmountIs")}
									</span>
								</Tooltip>
								<span className="vs--title-red vs--font-medium vs--font-small">
									{makeCurrencyText(currency.singleMax ?? Infinity, {
										currencyId: currency.currencyId ?? 0,
										decimalCount: currency.decimalCount ?? 0
									})}
								</span>
							</div>
						) : Number(bet.stake) < (currency.singleMin ?? 0) && bet.stake !== "" ? (
							<div className="vs--single-bet-stake-text vs--flex vs--flex-col vs--justify-center vs--text-right vs--single-bet-stake-error">
								<Tooltip placement="top" title={t("bet.minBetAmountIs")}>
									<span className="vs--title-red vs--font-regular vs--font-small vs--pb-4">
										{t("bet.minBetAmountIs")}
									</span>
								</Tooltip>
								<span className="vs--title-red vs--font-medium vs--font-small">
									{makeCurrencyText(currency.singleMin ?? Infinity, {
										currencyId: currency.currencyId ?? 0,
										decimalCount: currency.decimalCount ?? 0
									})}
								</span>
							</div>
						) : (
							<div className="vs--single-bet-stake-text vs--flex vs--flex-col vs--justify-center vs--text-right">
								<span className="vs--font-bold vs--font-small vs--single-bet-stake-text-title vs--pb-4">
									{useBonus ? t("bonus.bonus") + " " + t("bet.possibleWin") : t("bet.possibleWin")}
								</span>
								<span className="vs--title-white vs--font-medium vs--font-small">
									{renderPossibleWinAmount()}
								</span>
							</div>
						)}
					</div>
				) : null}
			</div>
			<BetTicketBorder svgProps={borderSvgStyle} />
		</div>
	);
};

/** SingleBet propTypes
 * PropTypes
 */
SingleBet.propTypes = {
	/** The bet of component */
	bet: betType,
	/** Index of bet in bets array */
	index: PropTypes.number,
	/** React prop, bets on the same events */
	groupedRepeatedBets: PropTypes.object,
	/** React property, focused Betslip/Bet stake  */
	focusedStakeId: PropTypes.string,
	/** React property, set last focused Betslip/Bet stake input  */
	setFocusedStakeId: PropTypes.func
};

export default SingleBet;

import { createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "translations/config";

import betService from "services/api/bet";

import { sliceName } from "./initialState";

import { useBonusThunk } from "store/slices/bonus/thunks";
import { saveSettingsThunk, setBonusThunk } from "store/slices/auth/thunks";
import { toggleFeedbackVisibilityAction } from "store/slices/common/actions";
import {
	removePendingAction,
	removePendingBetAction,
	updatePendingAction,
	addBetAction,
	setRigthbarTabAction,
	removeBetAction,
	clearBetsAction,
	setStakeAction,
	clearHiddenBetsAction,
	setModeAction,
	updatePendingBetAction,
	clearAllTypeBetsAction
} from "store/slices/betslip/actions";
import {
	cancelBetslipHistoryAction,
	updateHistoryBetSlipAction,
	updateHistoryBetSlipBetAction
} from "store/slices/betHistory/actions";
import { updateKenoCurrentBetsAction } from "store/slices/keno/actions";
import {
	selectSessionBonuses,
	selectIsQuickBetEnabled,
	selectQuickBetAmount,
	selectSessionGames,
	selectSessionId,
	selectUserId,
	selectSessionCurrency
} from "store/slices/auth/selectors";
import {
	selectBets,
	selectQuickBets,
	selectBetslipMode,
	selectBetslipStake,
	selectBetslipStakeMode
} from "store/slices/betslip/selectors";
import { selectCurrentGameType } from "store/slices/game/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";

import { generateBetKey } from "utils/bets";
import vsToast from "utils/toast";
import { sendPostMessageToParent } from "utils/iframe";
import { showError } from "utils/message";
import { binaryToFlags } from "utils/binaryCalculations";
import { BETSLIP_MODES, BETSLIP_STAKE_MODES, BETSLIP_BETS_LIMIT, BETSLIP_TABS } from "constants/betslip.constants";
import { GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import { BONUS_TYPE } from "constants/bonus.constants";

import { BET_STATE } from "constants/betslip.constants";
import { CLIENT_API } from "constants/integration.constants";
import { ERROR_RESOURCE, ERROR_STATUSES } from "constants/message.constants";

export const doBetThunk = createAsyncThunk(
	`${sliceName}/doBet`,
	async ({ isQuickBet = false, market, group, eventInfo, stake, onSuccess }, { signal, dispatch, getState }) => {
		try {
			const state = getState();
			const bonuses = selectSessionBonuses(state);
			const useBonus = selectUseBonus(state);
			const quickBetAmount = selectQuickBetAmount(state);
			const games = selectSessionGames(state);
			const bets = isQuickBet ? selectQuickBets(state) : selectBets(state);
			const mode = selectBetslipMode(state);
			const betSlipStake = selectBetslipStake(state);
			const stakeMode = selectBetslipStakeMode(state);
			const currentGameType = selectCurrentGameType(state);

			const betsCount = (bets ?? []).length;

			let bonusType = 0,
				bonusGameType = 0,
				bonusAmount = 0,
				bonusRoundCount = 0;
			if (bonuses.length > 0) {
				bonusType = bonuses[0].bonusType;
				bonusGameType = bonuses[0].gameType;
				bonusAmount = bonuses[0].amount;
				bonusRoundCount = bonuses[0].roundCount;
			}

			const isBetSelected = bets.some(
				(b) =>
					b.betId === market.id &&
					b.eventId === eventInfo.id &&
					![GAME_TYPE.KENO].includes(currentGameType) &&
					!(currentGameType === GAME_TYPE.LUCKY_SIX && market.id.includes(":"))
			);

			//TODO Need dynamic implementation with BO fore more flexibility
			// const isQuickBetOnboardingShown = !!getCookie(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);
			// const isQuickBetOnboardingShown = !!LocalStorageUtils.get(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);;

			// if (isQuickBetOnboardingShown && !globalVariables?.isMobile) {
			// 	const quickBettingOnBoardSession = SessionStorageUtils.get(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);
			// 	if (quickBettingOnBoardSession?.showTooltip) {
			// 		onBoardingEventTarget.dispatchEvent(new VSEvent(ON_BOARDING_TOOLTIP_VISIBILITY_EVENT, { state: false }));
			// 	}
			// }

			if (isBetSelected) {
				dispatch(
					removeBetAction({
						isQuickBet,
						key: null,
						betId: market.id,
						eventId: eventInfo.id,
						removeStake: !isQuickBet
					})
				);
				return;
			}

			if (betsCount > BETSLIP_BETS_LIMIT) {
				return;
			}

			let stakeToBet = stake === undefined ? "" : stake;

			if (isQuickBet && !stake && !window.isNaN(quickBetAmount)) {
				stakeToBet = quickBetAmount;
			}

			if (useBonus) {
				switch (bonusType) {
					case BONUS_TYPE.FREEBET:
						const availableGamesTypes = (games ?? []).map((game) => game.type);
						const bonusGamesTypes = binaryToFlags(availableGamesTypes, bonusGameType);

						if (
							(betsCount >= bonusRoundCount && mode === BETSLIP_MODES.SINGLE) ||
							bets.some((bet) => !bonusGamesTypes.includes(bet.gameType))
						) {
							const someBetOfSameEvent = bets.some((bet) => bet.eventId === eventInfo.id);
							if (bonusRoundCount !== 1 || someBetOfSameEvent) {
								vsToast.error(i18n.t("errors.message.InappropriateQuantity"));
								return;
							}
						}
						stakeToBet = bonusAmount;
						break;
					case BONUS_TYPE.FREEAMOUNT:
						if (!isQuickBet) {
							stakeToBet = stake === undefined ? "" : stake;
						}
						break;
					default:
						break;
				}
			}
			if (
				(!useBonus || bonusType !== BONUS_TYPE.FREEAMOUNT) &&
				stakeToBet === "" &&
				stakeMode === BETSLIP_STAKE_MODES.PER_BET &&
				mode === BETSLIP_MODES.SINGLE
			) {
				stakeToBet = betSlipStake;
			}

			const betGeneratedKey = generateBetKey({ eventId: eventInfo.id, betId: market.id, factor: market.factor });
			const generatedBet = {
				key: betGeneratedKey,
				gameData: eventInfo.gameData,
				factor: market.factor,
				name: market.showName,
				group: group,
				eventId: eventInfo.id,
				eventType: eventInfo.type,
				weekId: eventInfo.type === GAME_EVENT_TYPE.EVENT ? eventInfo.parentEventId : null,
				seasonId: eventInfo.seasonId,
				id: eventInfo.id,
				outcome: market.outcome,
				period: market.period,
				argument: market.argument,
				betId: market.id,
				gameType: currentGameType,
				hidden: market.hidden,
				stake: stakeToBet
			};

			dispatch(addBetAction(generatedBet, isQuickBet));
			dispatch(setRigthbarTabAction(BETSLIP_TABS.BETSLIP));

			if (typeof onSuccess === "function") {
				onSuccess(generatedBet);
			}
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const placeQuickBetThunk = createAsyncThunk(
	`${sliceName}/placeQuickBet`,
	async ({ isQuickBetslipOn = false, bet, onSuccess, onError }, { signal, dispatch, getState }) => {
		const state = getState();
		const bonuses = selectSessionBonuses(state);
		const useBonus = selectUseBonus(state);
		const sessionId = selectSessionId(state);
		const userId = selectUserId(state);
		const betslipStake = selectBetslipStake(state);
		const quickBetAmount = selectQuickBetAmount(state);

		try {
			let bonusId = null;
			if (bonuses.length > 0) {
				bonusId = bonuses[0].bonusId;
			}

			const data = {
				type: BETSLIP_MODES.SINGLE,
				bets: [bet].map((b) => ({
					eventId: b.eventId,
					oddId: b.betId,
					amount: b.stake
				}))
			};

			if (useBonus && bonusId) {
				data.bonusId = bonusId;
			}

			const response = await betService.placeBet(data, signal);
			const placeBetData = response.data.value;

			sendPostMessageToParent({ type: CLIENT_API.BET, bet: placeBetData.totalAmount });
			dispatch(clearBetsAction());
			if (Number(betslipStake) !== quickBetAmount) {
				dispatch(setStakeAction(String(quickBetAmount)));
			}
			dispatch(removeBetAction({ isQuickBet: true, key: bet.key, removeStake: false }));
			dispatch(toggleFeedbackVisibilityAction(`vs__feedback_is_shown_${userId}`));

			typeof onSuccess === "function" && onSuccess();

			return placeBetData;
		} catch (error) {
			if (
				useBonus &&
				error?.isAxiosError &&
				((error?.response?.data?.resource === ERROR_RESOURCE.PLAYER_BONUS &&
					error?.response?.data?.status === ERROR_STATUSES.NOT_ALLOWED) ||
					(error?.response?.data?.resource === ERROR_RESOURCE.OPERATOR &&
						error?.response?.data?.status === ERROR_STATUSES.INVALID_PARAMETERS))
			) {
				const bonusForRemove = bonuses?.[0];

				dispatch(useBonusThunk(false));
				if (bonusForRemove) {
					if (bonusForRemove.bonusType === BONUS_TYPE.FREEBET) {
						bonusForRemove.roundCount = 0;
					} else {
						bonusForRemove.amount = 0;
					}

					dispatch(setBonusThunk({ bonus: bonusForRemove, sessionId }));
				}
			}
			if (!error?.isAxiosError) {
				showError({
					message: error?.response?.data?.message ?? "",
					status: error?.response?.data?.status ?? "",
					resource: error?.response?.data?.resource ?? ""
				});
			}
			dispatch(clearBetsAction());
			dispatch(removeBetAction({ isQuickBet: true, key: bet.key, removeStake: false }));

			onError(bet);

			return Promise.reject(error);
		}
	}
);

export const placeBetThunk = createAsyncThunk(
	`${sliceName}/placeBet`,
	async (onSuccess, { signal, dispatch, getState }) => {
		const state = getState();
		const bonuses = selectSessionBonuses(state);
		const useBonus = selectUseBonus(state);
		const sessionId = selectSessionId(state);
		const userId = selectUserId(state);
		const bets = selectBets(state);
		const mode = selectBetslipMode(state);
		const betslipStake = selectBetslipStake(state);
		const isQuickBetEnabled = selectIsQuickBetEnabled(state);

		try {
			let bonusId = null;
			if (bonuses.length > 0) {
				bonusId = bonuses[0].bonusId;
			}

			const data = {
				type: mode,
				bets: bets
					.filter((b) => !b.isExpired && b.stake)
					.map((b) => ({
						eventId: b.eventId,
						oddId: b.betId,
						amount: mode === BETSLIP_MODES.SINGLE ? b.stake : 0
					}))
			};

			if (useBonus && bonusId) {
				data.bonusId = bonusId;
			}

			if (mode === BETSLIP_MODES.MULTI) {
				data.totalAmount = betslipStake;
			}

			const response = await betService.placeBet(data, signal);
			const placeBetData = response.data.value;

			sendPostMessageToParent({ type: CLIENT_API.BET, bet: placeBetData.totalAmount });
			dispatch(clearBetsAction());
			if (!isQuickBetEnabled) {
				dispatch(setStakeAction(""));
			}
			dispatch(toggleFeedbackVisibilityAction(`vs__feedback_is_shown_${userId}`));

			typeof onSuccess === "function" && onSuccess();

			return placeBetData;
		} catch (error) {
			if (
				useBonus &&
				error?.isAxiosError &&
				((error?.response?.data?.resource === ERROR_RESOURCE.PLAYER_BONUS &&
					error?.response?.data?.status === ERROR_STATUSES.NOT_ALLOWED) ||
					(error?.response?.data?.resource === ERROR_RESOURCE.OPERATOR &&
						error?.response?.data?.status === ERROR_STATUSES.INVALID_PARAMETERS))
			) {
				const bonusForRemove = bonuses?.[0];
				dispatch(clearBetsAction());
				if (!isQuickBetEnabled) {
					dispatch(setStakeAction(""));
				}
				dispatch(useBonusThunk(false));

				if (bonusForRemove) {
					if (bonusForRemove.bonusType === BONUS_TYPE.FREEBET) {
						bonusForRemove.roundCount = 0;
					} else {
						bonusForRemove.amount = 0;
					}

					dispatch(setBonusThunk({ bonus: bonusForRemove, sessionId }));
				}
			} else {
				dispatch(clearHiddenBetsAction());
			}
			if (!error?.isAxiosError) {
				showError({
					message: error?.response?.data?.message ?? "",
					status: error?.response?.data?.status ?? "",
					resource: error?.response?.data?.resource ?? ""
				});
			}

			return Promise.reject(error);
		}
	}
);

export const setBetslipModeThunk = createAsyncThunk(
	`${sliceName}/setBetslipMode`,
	async (mode, { signal, dispatch, getState }) => {
		const state = getState();
		const currency = selectSessionCurrency(state);
		const bonuses = selectSessionBonuses(state);
		const useBonus = selectUseBonus(state);
		const isQuickBetEnabled = selectIsQuickBetEnabled(state);

		let bonusType = 0,
			bonusRoundCount = 0,
			bonusAmount = 0;
		if (bonuses.length > 0) {
			bonusType = bonuses[0].bonusType;
			bonusRoundCount = bonuses[0].roundCount;
			bonusAmount = bonuses[0].amount;
		}

		if (useBonus && mode === BETSLIP_MODES.SINGLE) {
			const bets = state?.betslip?.bets ?? [];

			const getMinCurrency = () => {
				if (!currency || !mode) {
					return 0;
				}
				if (mode === BETSLIP_MODES.SINGLE) {
					return currency.singleMin;
				}
				return currency.multiMin;
			};

			switch (bonusType) {
				case BONUS_TYPE.FREEBET:
					if (bets.length > bonusRoundCount) {
						return vsToast.error(i18n.t("errors.message.InappropriateQuantity"));
					}
					break;
				case BONUS_TYPE.FREEAMOUNT:
					if (bonusAmount < getMinCurrency() && bonusAmount > 0) {
						return vsToast.error(i18n.t("errors.message.InappropriateQuantity"));
					}
					dispatch(setStakeAction(""));
					break;
				default:
					break;
			}
		} else if (useBonus && bonusType === BONUS_TYPE.FREEBET && mode === BETSLIP_MODES.MULTI) {
			dispatch(setStakeAction(bonusAmount));
		} else if (!isQuickBetEnabled && (!useBonus || (useBonus && mode !== BETSLIP_MODES.MULTI))) {
			dispatch(setStakeAction(""));
		}
		dispatch(setModeAction(mode));
	}
);

export const useQuickBetThunk = createAsyncThunk(
	`${sliceName}/useQuickBet`,
	async (enabled, { signal, dispatch, getState }) => {
		const state = getState();
		const currency = selectSessionCurrency(state);
		const bonuses = selectSessionBonuses(state);
		const useBonus = selectUseBonus(state);
		const quickBetAmount = selectQuickBetAmount(state);

		if (!enabled) {
			return;
		}

		const singleMin = currency?.singleMin ?? 1;
		dispatch(setBetslipModeThunk(BETSLIP_MODES.SINGLE));

		if (!useBonus) {
			dispatch(setStakeAction(quickBetAmount));
			return;
		}

		const currentBonus = bonuses?.[0] ?? {};
		const isFreeAmountBonus = currentBonus?.bonusType === BONUS_TYPE.FREEAMOUNT;
		let bonusAmount = currentBonus.amount;

		if (isFreeAmountBonus) {
			const difference = currentBonus.amount - singleMin;
			const totalStack = difference < singleMin ? currentBonus.amount : singleMin;
			bonusAmount = totalStack;
		}

		dispatch(setStakeAction(bonusAmount));
		if (bonusAmount !== Number(quickBetAmount)) {
			dispatch(saveSettingsThunk({ quickBetAmount: bonusAmount }));
		}
	}
);

export const pendingBetsThunk = createAsyncThunk(`${sliceName}/pendingBets`, async (_, { signal }) => {
	const response = await betService.pendings(signal);

	return response.data.value;
});

export const cancelBetSlipThunk = createAsyncThunk(`${sliceName}/cancelBetslip`, async (id, { signal, dispatch }) => {
	const response = await betService.cancelBetslip(id, signal);

	dispatch(removePendingAction(response.data.value));
	dispatch(cancelBetslipHistoryAction(response.data.value));
	dispatch(updateKenoCurrentBetsAction(response.data.value));

	return response.data.value;
});

export const cancelSingleBetThunk = createAsyncThunk(
	`${sliceName}/cancelSingleBet`,
	async (requestBody, { signal, dispatch }) => {
		const response = await betService.cancelSingleBet(requestBody, signal);
		const data = response.data.value;

		if (data.item2.state === BET_STATE.CANCELLED) {
			dispatch(removePendingBetAction({ id: requestBody.betId }));
		} else {
			dispatch(updatePendingBetAction(data.item2));
		}
		dispatch(updateHistoryBetSlipAction(response.data.value.item1));
		dispatch(updateHistoryBetSlipBetAction(response.data.value.item2));

		return response.data.value;
	}
);

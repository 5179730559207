import { PERIOD, ODD_FORMATS } from "constants/market.constants";
import BaseMarkets from "./baseMarkets";
import { groupBy } from "utils/common";
import { LUCKY_SIX_MARKETS } from "constants/lucky-six.constants.js";
import i18next from "i18next";

class LuckySixMarkets extends BaseMarkets {
	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof FootballMarkets
	 */
	getGroupsStructure() {
		return {
			sideBets: {
				groups: [
					{
						group: LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER,
						argument: (arg) => Number(arg) === 24.5
					},
					{
						group: LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER,
						argument: (arg) => Number(arg) === 24.5
					},
					{
						group: LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN
					},
					{
						group: LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN
					},
					{
						group: LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER,
						argument: (arg) => Number(arg) === 122.5
					},
					{
						group: LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN
					},
					{
						group: LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN
					},
					{
						group: LUCKY_SIX_MARKETS.FIRST_BALL_COLOR,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.FIRST_BALL_COLOR
					},
					{
						group: LUCKY_SIX_MARKETS.LAST_BALL_COLOR,
						period: PERIOD.NONE,
						title: LUCKY_SIX_MARKETS.LAST_BALL_COLOR
					}
				]
			}
		};
	}

	makeMarketsArray(data, gameData, rtps, gameType) {
		const obj = {};
		const grouped = groupBy(data, (e) => e.groupTitle + "__" + e.period);

		Object.keys(grouped).forEach((o) => {
			const period = o.split("__")[1] ? Number(o.split("__")[1]) : null;

			obj[o] = {
				bets: [],
				period: period,
				group: grouped[o][0].group,
				statistics: grouped[o][0].statistics,
				isNew: grouped[o][0].isNew
			};

			grouped[o].forEach((g) => {
				let oddList = g.oddList || [];

				oddList.forEach((odd) => {
					obj[o].bets.push({
						name: odd.outcome,
						factor: odd.factor,
						oddState: odd.state,
						outcome: odd.outcome,
						argument: g.argument,
						id: odd.id,
						showName: this.makeName(odd.outcome, gameType)
					});
				});
			});
		});

		const arr = [];

		Object.keys(obj).forEach((o) => {
			let item = {
				group: o.split("__")[0],
				bets: obj[o].bets.map((b) => (b.factor >= 1.01 ? b : { ...b, disabled: true })),
				period: o.split("__")[1] ? Number(o.split("__")[1]) : null,
				systemGroup: obj[o].group,
				statistics: obj[o].statistics,
				isNew: obj[o].isNew
			};
			if (item.bets.length > 1 && item.bets.every((bet) => bet.factor > 1.01)) {
				arr.push(item);
			}
		});

		return arr;
	}

	makeGroupTitle({ group, gameType }) {
		switch (group) {
			case LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER:
			case LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN:
				return i18next.t(`markets.${gameType}.firstNumber`);
			case LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER:
			case LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN:
				return i18next.t(`markets.${gameType}.lastNumber`);
			case LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER:
			case LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN:
				return i18next.t(`markets.${gameType}.preNumbers`);
			case LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN:
				return i18next.t(`markets.${gameType}.mostlyDrawn`);
			case LUCKY_SIX_MARKETS.FIRST_BALL_COLOR:
				return i18next.t(`markets.${gameType}.firstBallColor`);
			case LUCKY_SIX_MARKETS.LAST_BALL_COLOR:
				return i18next.t(`markets.${gameType}.lastBallColor`);
		}
	}

	makeName(outcome, gameType) {
		switch (outcome) {
			case "Over":
				return i18next.t(`markets.${gameType}.over`);
			case "Under":
				return i18next.t(`markets.${gameType}.under`);
			case "Odd":
				return i18next.t(`markets.${gameType}.odd`);
			case "Even":
				return i18next.t(`markets.${gameType}.even`);
			case "Yes":
				return i18next.t(`markets.${gameType}.even`);
			case "No":
				return i18next.t(`markets.${gameType}.odd`);
			case "Red":
			case "Orange":
			case "Yellow":
			case "Green":
			case "Blue":
			case "Violet":
			case "Purple":
			case "Black":
				return i18next.t(`markets.${gameType}.${outcome.toLowerCase()}`);
		}
	}

	makeBetText(bet) {
		const { gameType, market: group, outcome, argument } = bet;

		return `${this.makeGroupTitle({ group, gameType })} : ${this.makeName(outcome, gameType)} ${argument ?? ""}`;
	}
}

export default LuckySixMarkets;

import { NUMBER_TO_COLOR_MAPPER } from "constants/lucky-six.constants.js";
import useAppSelector from "hooks/store/useAppSelector.js";
import { selectLuckySixSelectedBalls } from "store/slices/lucky-six/selectors.js";
import { addSelectedBallsAction, removeSelectedBallsAction } from "store/slices/lucky-six/actions.js";
import useAppDispatch from "hooks/store/useAppDispatch.js";

const BetBalls = ({ numbers = [], border, clickable = true }) => {
	const selectedBalls = useAppSelector(selectLuckySixSelectedBalls);

	const dispatch = useAppDispatch();

	const handleBallClick = (number) => {
		if (selectedBalls.includes(number)) {
			return dispatch(removeSelectedBallsAction(number));
		}

		if (selectedBalls.length < 6) {
			return dispatch(addSelectedBallsAction(number));
		}
	};

	const borderClass =
		border === "small" ? "vs--lucky-six-ball-border-small" : border === "big" ? "vs--lucky-six-ball-border-big" : "";

	return (
		<>
			{numbers.map((number, i) => {
				const colorClass = NUMBER_TO_COLOR_MAPPER[number % 8];
				const isActive = selectedBalls.includes(number);

				return (
					<div
						key={i}
						className={`vs--lucky-six-ball vs--flex vs--justify-center vs--align-center vs--lucky-six-ball-${colorClass} ${borderClass} ${
							isActive ? "vs--lucky-six-ball-active" : ""
						 } ${clickable ? "vs--lucky-six-ball-clickable" : ""}`}
						 onClick={() => clickable && handleBallClick(number)}
					>
						<span className="vs--lucky-six-ball-number">{number}</span>

						<svg
							className="vs--lucky-six-ball-shadow"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="1.1 0 40 40"
							fill="none"
						>
							<path
								opacity="0.2"
								d="M40.81 17.3174C40.81 17.3174 32.3447 15.5341 25.0199 11.2755C17.6951 7.01692 11.5901 2.4491 11.5901 2.4491C14.0902 0.782477 22.5194 -2.2336 31.0888 2.7486C38.4136 7.00717 40.5834 14.1662 40.81 17.3174Z"
								fill="url(#paint0_linear_9546_1627)"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_9546_1627"
									x1="29.3707"
									y1="3.5376"
									x2="25.1049"
									y2="11.2375"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="white" />
									<stop offset="1" stopColor="white" stopOpacity="0" />
								</linearGradient>
							</defs>
						</svg>

						<svg className="vs--lucky-six-ball-shadow-hover" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
							<g id="hover shadow" filter="url(#filter0_ii_21701_128240)">
							<rect width="48" height="48" rx="24" fill="white" fillOpacity="0.01"/>
							</g>
							<defs>
							<filter id="filter0_ii_21701_128240" x="-2" y="-2" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix"/>
							<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
							<feOffset dx="-4" dy="4"/>
							<feGaussianBlur stdDeviation="1"/>
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"/>
							<feBlend mode="normal" in2="shape" result="effect1_innerShadow_21701_128240"/>
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
							<feOffset dx="4" dy="-4"/>
							<feGaussianBlur stdDeviation="1"/>
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
							<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"/>
							<feBlend mode="normal" in2="effect1_innerShadow_21701_128240" result="effect2_innerShadow_21701_128240"/>
							</filter>
							</defs>
						</svg>

					</div>
				);
			})}
		</>
	);
};

export default BetBalls;

import { sliceName } from "./initialState";

const emptyArray = [];

export const selectResultMarketsByEventId = (eventId) => (state) =>
	state[sliceName].resultMarkets[eventId] || emptyArray;

export const selectLuckySixSelectedBalls = (state) => state[sliceName].selectedBalls;

export const selectDrawnBalls = (state) => state[sliceName].drawnBalls;

/** Build css url rom session
 * @function
 * @param {string} cssId - css file id
 * @param {string} partnerId - partner id
 * @param {string} projectId - project id
 * @returns {string} - url
 */
export const buildCssUrl = (cssId, partnerId, projectId) => {
	return `${import.meta.env.SYSTEM_CDN_URL}/${partnerId}/projects/${projectId}/ui/${cssId}.css`
}

/** Append css to body
 * @function
 * @param {string} url - css file url
 */
export const appendCss = (url) => {
	const body = document.getElementsByTagName("body")[0];
	const link = document.createElement("link");
	link.rel = "stylesheet";
	link.type = "text/css";
	link.href = url;
	link.media = "all";
	body.appendChild(link);
	return link;
};

/** Append inline css to body
 * @function
 * @param {string} content - style tag content
 * @param {string} id - style tag id
 */
export const appendInlineCss = (content, id) => {
	const body = document.getElementsByTagName("body")[0];
	let style = document.getElementById(id);
	if (style) {
		style.innerHTML = content;
	} else {
		style = document.createElement("style");
		style.innerHTML = content;
		body.appendChild(style);
	}
};

/** Generate css from properties
 * @function
 * @param {object} data
 * @param {string} id - style tag id
 */
export const generateCssFromProperties = (data, id) => {
	let str = ":root{\n";
	data.forEach((prop) => {
		str += `${prop.key}: ${prop.value};\n`;
	});
	str += "}";
	appendInlineCss(str, id);
};

import { sliceName } from "./initialState";
import { GAME_STATUSES } from "constants/game.constants.js";

const seasonResultsDefaultData = { startTime: new Date() };

export const selectCurrentGameType = (state) => state[sliceName].currentGameType;
export const selectCurrentEventId = (state) => state[sliceName].currentEventId;
export const selectLiveAndUpcomings = (state) => state[sliceName].liveAndUpcomings;
export const selectMatches = (state) => state[sliceName].matches;
export const selectResults = (state) => state[sliceName].results;
export const selectLastResults = (state) => state[sliceName].lastResults;
export const selectSeasonResults = (state) => state[sliceName].seasonResult;

export const selectIsBonusBetDisabled = (state) => state[sliceName].isBonusBetDisabled;
export const selectMarketsActiveKeys = (state) => state[sliceName].marketsActiveKeys;
export const selectGameCountdown = (state) => state[sliceName].gameCountDown;
export const createGivenGameCountdownSelector = (gameId) => (state) => selectGameCountdown(state)?.[gameId];

export const selectIsLiveAndUpcomingsLoading = (state) => selectLiveAndUpcomings(state).loading;
export const selectLiveAndUpcomingsData = (state) => selectLiveAndUpcomings(state).data;
export const selectKenoCurrentEvent = (state) => selectLiveAndUpcomings(state).data[0];
export const selectLuckySixLiveEvent = (state) => selectLiveAndUpcomings(state).data[0];
export const selectLuckySixCurrentEvent = (state) =>
	selectLiveAndUpcomings(state).data.find((event) =>
		[GAME_STATUSES.NEW, GAME_STATUSES.PREAMBLE_STARTED].includes(event.status)
	);
export const selectCurrenSeasonEventId = (state) => selectLiveAndUpcomings(state).data[0]?.seasonId ?? null;
export const selectMatchesData = (state) => selectMatches(state).data;
export const createGivenMatchDataSelector = (matchId) => (state) => selectMatches(state).data?.[matchId];
export const selectIsMatchesLoading = (state) => selectMatches(state).loading;
export const selectIsResultsLoading = (state) => selectResults(state).loading;
export const selectResultsData = (state) => selectResults(state).data;
export const selectResultsFilters = (state) => selectResults(state).filters;
export const selectResultsFiltersPage = (state) => selectResults(state).filters.page;
export const selectResultsFiltersTotal = (state) => selectResults(state).total;
export const selectLastResultsData = (state) => selectLastResults(state).data;
export const selectIsLastResultsLoading = (state) => selectLastResults(state).loading;
export const selectSeasonResultsId = (state) => selectSeasonResults(state).seasonId;
export const selectIsSeasonResultsLoading = (state) => selectSeasonResults(state).loading;
export const selectSeasonResultsData = (state) => selectSeasonResults(state).data || seasonResultsDefaultData;
export const selectSeasonResultsFilters = (state) => selectSeasonResults(state).filters;

import useGlobalVariables from "hooks/useGlobalVariables";
import { useTranslation } from "react-i18next";
import useAppSelector from "hooks/store/useAppSelector";
import useAppDispatch from "hooks/store/useAppDispatch";

import Loader from "components/ui/loader";

import usePlayForReal from "hooks/auth/usePlayForReal";

import { placeBetThunk } from "store/slices/betslip/thunks";
import { saveSettingsThunk } from "store/slices/auth/thunks";
import { clearBetsAction, setStakeAction, setShowMobileBetSlipsModalAction } from "store/slices/betslip/actions";

import {
	selectIsDemoSession,
	selectSessionCurrency,
	selectIsQuickBetEnabled,
	selectIsSoundOn,
	selectQuickBetAmount,
	selectSwitchingToRealMode,
	selectIsRtl
} from "store/slices/auth/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";
import {
	selectBets,
	selectBetslipStake,
	selectBetslipMode,
	selectIsPlaceBetLoading
} from "store/slices/betslip/selectors";

import { playAudio, mergeClassNames, numberWithSpaces, buildPathToStaticFolderOfCDN } from "utils/common";
import { navigateToPage } from "utils/navigate";
import { calculateTotalStake } from "utils/bets";
import { BETSLIP_MODES } from "constants/betslip.constants";
import Paths from "constants/path.constants";
import { useNavigate } from "react-router";
import { Fragment } from "react";

import SessionStorageUtils from "utils/sessionStorage";

const maskNaNDecorator = (fn) => {
	if (typeof fn !== "function") {
		return fn;
	}
	return (...args) => {
		const result = fn(...args);
		if (window.isNaN(result)) {
			return 0;
		}

		return result;
	};
};

const BetButtons = ({
	className = "",
	isQuickBetslipOn = false,
	hasError = Function.prototype,
	focusedStakeId = null,
	placeBetHandler = null,
	checkPlaceBetAvailability = null,
	countTotalStake = null
}) => {
	const isDemo = useAppSelector(selectIsDemoSession);
	const currency = useAppSelector(selectSessionCurrency);
	const isSound = useAppSelector(selectIsSoundOn);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const quickBetAmount = useAppSelector(selectQuickBetAmount);
	const switchingToRealMode = useAppSelector(selectSwitchingToRealMode);
	const useBonus = useAppSelector(selectUseBonus);
	const bets = useAppSelector(selectBets);
	const stake = useAppSelector(selectBetslipStake);
	const mode = useAppSelector(selectBetslipMode);
	const isLoading = useAppSelector(selectIsPlaceBetLoading);
	const isRTLLanguage = useAppSelector(selectIsRtl);

	const dispatch = useAppDispatch();

	const { t } = useTranslation();
	const globalVariables = useGlobalVariables();

	const playForReal = usePlayForReal();

	const { isMobile, isPreview } = globalVariables;
	const navigate = useNavigate();

	const activeBets = bets.filter((bet) => !bet.isExpired);

	const isThereError = hasError();

	const totalStakeCb = maskNaNDecorator(countTotalStake !== null ? countTotalStake : calculateTotalStake);

	const getMinLimit = () => (mode === BETSLIP_MODES.SINGLE ? currency.singleMin ?? 0 : currency.multiMin ?? 0);

	const getMaxLimit = () =>
		mode === BETSLIP_MODES.SINGLE ? currency.singleMax ?? Infinity : currency.multiMax ?? Infinity;

	const isPlaceBetDisabled = () => {
		if (checkPlaceBetAvailability) {
			return checkPlaceBetAvailability();
		}

		const mapped = bets.map((b) => b.eventId);

		return (
			(mode === BETSLIP_MODES.MULTI && (stake === "" || mapped.length !== new Set(mapped).size)) ||
			isThereError ||
			isLoading ||
			(mode === BETSLIP_MODES.SINGLE &&
				bets.filter(
					(b) =>
						((Number(b.stake) < getMinLimit() || Number(b.stake) > getMaxLimit()) && b.stake !== "") ||
						["", "."].includes(b.stake)
				).length > 0)
		);
	};

	const isBetDisabled = isPlaceBetDisabled();

	const handlePlaceBet = () => {
		if (isBetDisabled) {
			return;
		}

		if (placeBetHandler) {
			return placeBetHandler();
		}

		SessionStorageUtils.set("repeatBet", false);

		dispatch(
			placeBetThunk(() => {
				if (isMobile) {
					navigateToPage(navigate, Paths.HOME);
					dispatch(setShowMobileBetSlipsModalAction(false));
					if (isSound) {
						playAudio(buildPathToStaticFolderOfCDN("media/success.mp3"));
					}
				}
			})
		);
	};

	const handlePlayForRealClick = () => {
		playForReal();

		SessionStorageUtils.set("repeatBet", false);
	};

	const handleDeleteAllClick = () => {
		dispatch(clearBetsAction());
		dispatch(setStakeAction(""));

		if (isMobile) {
			dispatch(setShowMobileBetSlipsModalAction(false));
		}
	};

	if (isMobile && focusedStakeId !== null && isBetDisabled) {
		return null;
	}

	return (
		<div
			className={mergeClassNames(
				"vs--flex vs--align-center vs--justify-center",
				"vs--betslip-stake-buttons",
				className
			)}
		>
			{(!isQuickBetEnabled || isQuickBetslipOn) && (
				<Fragment>
					{!isQuickBetslipOn && (
						<div
							className="vs--betslip-stake-buttons-actions-delete vs--flex vs--align-center vs--pt-6 vs--pb-6 vs--pl-8 vs--pr-8"
							onClick={handleDeleteAllClick}
						>
							<i className="ic_delete vs--font-bigest" />
						</div>
					)}
					<button
						type="button"
						className={mergeClassNames(
							"vs--betslip-stake-buttons-bet vs--button-primary",
							"vs--button  vs--font-normal vs--font-medium vs--text-cut",
							isPlaceBetDisabled() && "vs--button-disabled"
						)}
						onClick={handlePlaceBet}
					>
						{useBonus ? (
							t("bet.placeBonusBet")
						) : isDemo ? (
							t("bet.placeDemoBet")
						) : (
							<Fragment>
								<span>{`${t("bet.placeBet")}: `}</span>

								<span className="vs--stake-btn">
									{numberWithSpaces(
										Number(
											totalStakeCb(
												activeBets,
												currency.decimalCount,
												mode === BETSLIP_MODES.MULTI ? stake : null
											)
										),
										isRTLLanguage ? { reverse: true, separator: "" } : undefined
									)}
								</span>

								<span>{` ${currency.code}`}</span>
							</Fragment>
						)}
						{isLoading && <Loader />}
					</button>
				</Fragment>
			)}
			{!isMobile && isQuickBetEnabled && !isDemo && (
				<button
					type="button"
					className={mergeClassNames(
						"vs--button vs--button-primary",
						"vs--font-normal vs--font-condensed vs--font-medium",
						(quickBetAmount === Number(stake) || isThereError) && "vs--button-disabled"
					)}
					disabled={quickBetAmount === Number(stake) || isThereError}
					onClick={() => {
						if (!isThereError) {
							dispatch(saveSettingsThunk({ quickBetAmount: stake }));
						}
					}}
				>
					{quickBetAmount === Number(stake) ? (
						<div className="vs--flex vs--align-center vs--justify-center">
							<div className="vs--mr-4">
								<span>{t("common.applied")}</span>
								<span>:</span>
							</div>
							<span className="vs--mr-4">{quickBetAmount}</span>
							<span> {currency.code}</span>
						</div>
					) : (
						t("common.apply")
					)}
				</button>
			)}
		</div>
	);
};

export default BetButtons;

export const ADMIN_API_LISTENER_EVENTS = {
	BALANCE: "Balance",
	LOGOUT: "Logout",
	UPDATE_PROJECT_CURRENCY: "UpdateProjectCurrency",
	GAME_STATE_CHANGE: "GameStateChanged",
	BET_SLIP: "BetSlip",
	BET_SLIP_BET: "BetSlipBet",
	BONUS: "Bonus"
};

export const SITE_API_LISTENER_EVENTS = {
	BALANCE: "Balance",
	BONUS: "Bonus",
	BET_SLIP: "BetSlip",
	BET_SLIP_BET: "BetSlipBet"
};

export const JOB_API_LISTENER_EVENTS = {
	BALANCE: "Balance",
	WON_POPUP: "WonPopup",
	EVENTS: "Events",
	EVENT_STATE: "EventState",
	EVENT_POSITIONS: "EventPositions",
	TEAM_STANDINGS: "TeamStandings",
	KENO_STATISTICS: "KenoStatistics",
	LUCKY_SIX_STATISTICS: "LuckySixStatistics",
	BET_SLIP: "BetSlip",
	BET_SLIP_BET: "BetSlipBet"
};

export const ADMIN_API_INVOKE_EVENTS = {
	SUBSCRIBE: "Subscribe",
	UNSUBSCRIBE: "Unsubscribe"
};

export const JOB_API_INVOKE_EVENTS = {
	SUBSCRIBE: "Subscribe",
	UNSUBSCRIBE: "Unsubscribe"
};

export const SITE_API_INVOKE_EVENTS = {
	UPDATE_SESSION: "UpdateSession"
};

export const UPDATE_SESSION_INTERVAL_MILLISECONDS = 60000;
